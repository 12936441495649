

























import Vue from 'vue'
import ProfileBillingAvailableSubscriptionsPlanInfo from './ProfileBillingAvailableSubscriptionsPlanInfo.vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ProfileBillingAvailableSubscriptions',
  components: { ProfileBillingAvailableSubscriptionsPlanInfo },
  data () {
    return {
      selectedPeriod: {},
      options: []
    }
  },
  computed: {
    ...mapGetters('plans', ['getPlans']),
    ...mapGetters('profile', ['getCurrentPlan']),
    availablePlans () {
        if (!this.getCurrentPlan) {
            return this.getPlans.plans
        } else {
            if (!this.getPlans.plans) {
                return []
            }
            return this.getPlans.plans.filter((plan) => { return plan.id !== this.getCurrentPlan.id })
        }
    }
  }
})
